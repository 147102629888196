import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";

import "../styles/process.css";

export default function CloseDoor() {
  const history = useHistory();
  window.addEventListener("popstate", (e) => {
    // Nope, go back to your page
    history.go(1);
  });

  return (
    <AppLayout title={""}>
      <div className="payment-loader">
        <div className="pad">
          <div className="chip"></div>
          <div className="line line1"></div>
          <div className="line line2"></div>
        </div>
        <div className="loader-text">{"Updating machine stock ..."} </div>
      </div>
    </AppLayout>
  );
}
