export default {
  //BOTTOM NAV
  home: "Início",
  buybye: "BuyBye",
  account: "Conta",
  purchaseList: "Produtos",
  //HOME
  openBuyBye: "Abrir para Reaprovisionamento",
  // CABINET
  openBuyByeTitle: "Bem-vindo ao BuyBye",
  openBuyByeSubtitle: "Insira o código para reaprovisionar.",
  // receipts
  purchaseLabel: "Compra",
  receiptSubtitle: (value) => {
    return `Comprou ${value} items.`;
  },
  receiptDuration: (value) => {
    return `Your Purchase Time Was <strong>${value}s.</strong>`;
  },
  global:{
    email:"E-mail",
    password:"Palavra-passe",
    signIn:"Login"
  }
};
