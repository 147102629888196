import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";

export default function SignIn(props) {
  const history = useHistory();
  const _doc = props.match.params.id;
  const onClose = () => {
    history.goBack();
    return;
  };

  return <AppLayout title={`${_doc}`} onClick={onClose}></AppLayout>;
}
