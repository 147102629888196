import React from "react";
import ReactCodeInput from "react-code-input";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    codeContainer: {
      display: "flex",
      justifyContent: "space-around",
    },
    header: {
      position: "relative",
      height: "60px",
      //overflow: "hidden",
      margin: "20px 0",
      willChange: "transform, opacity",
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      color: "white",
      width: "95%",
    },
    btn: {
      position: "absolute",
      width: "100%",
      top: "76%",
      color: "white",
    },
  }));

export default function CodeInput({sendCode,
  ...rest
}) {
    
    const classes = useStyles();

    const codeStyle = {
        inputStyle: {
          fontFamily:
            "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
          margin: "5px",
          MozAppearance: "textfield",
          width: "50px",
          borderRadius: "6px",
          fontSize: "1.5em",
          height: "50px",
          textAlign: "center",
          color: "black",
          border: "1px solid lightgray",
        },
        inputStyleInvalid: {
          fontFamily:
            "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
          margin: "5px",
          MozAppearance: "textfield",
          width: "50px",
          borderRadius: "6px",
          textAlign: "center",
          fontSize: "1.5em",
          height: "50px",
          color: "black",
          border: "1px solid lightgray",
        },
        onChange: async (a) => {
          if (a.length === 4) {
            sendCode(a);
          }
        },
      };

  return (
    <div className={clsx(classes.codeContainer)}>
    <ReactCodeInput type="number" fields={4} {...codeStyle} />
    </div>
  );
}
