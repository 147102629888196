export default {
  //BOTTOM NAV
  home: "Home",
  buybye: "BuyBye",
  account: "Account",
  purchaseList: "Products",
  //HOME
  openBuyBye: "Open BuyBye",
  // CABINET
  openBuyByeTitle: "Welcome to BuyBye",
  openBuyByeSubtitle: "Insert Cabinet code to replenish",
  // receipts
  purchaseLabel: "Purchase",
  receiptSubtitle: (value) => {
    return `You Purchased ${value} items.`;
  },
  receiptDuration: (value) => {
    return `Your Purchase Time Was <strong>${value}s.</strong>`;
  },
  global:{
    email:"E-mail",
    password:"Palavra-passe",
    signIn:"Login"
  }
};
