import React, { useEffect } from "react";
import AppLayout from "./layout";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../components/container";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AuthService from "../services/auth-service";
import BuyByeService from "../services/buybye-service";
import moment from "moment";
import Loader from "./loader";
import HelpIcon from "@material-ui/icons/Help";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
const useStyles = makeStyles((theme) => ({
  tripTime: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1),
    marginBottom: "15vh",
  },
  codeContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(1),
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "space-around",
  },
  totalCard: {
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "space-between",
    margin: "10px 0px 20px 0px",
  },
  header: {
    position: "relative",
    height: "60px",
    overflow: "hidden",
    margin: "20px 0",
    willChange: "transform, opacity",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    color: "white",
    width: "95%",
  },
  btn: {
    position: "absolute",
    width: "100%",
    top: "81%",
    color: "white",
  },
}));
const Address = ({ data }) => (
  <div>
    <address className="header__address">{data.subtitle}</address>
  </div>
);
const HeaderTitle = ({ data }) => (
  <h3 className="header__title" style={{ fontSize: "1.2em" }}>
    {data.title}
  </h3>
);

const HeaderTop = ({ data, onClick }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
    <HelpIcon onClick={onClick} style={{ cursor: "pointer" }}></HelpIcon>
  </div>
);
const HeaderItem = ({ data, onClick }) => {
  return (
    <div className="header__item">
      <HeaderTop data={data} onClick={onClick} />
      <Address data={data} />
    </div>
  );
};

const TotalCard = ({ classes, data, id }) => {
  return (
    <div className={classes.totalCard}>
      <div>Total</div>
      <div>
        <strong>
          {data.currency} {data.totalValue}
        </strong>
      </div>
    </div>
  );
};

const ProductsCard = ({ classes, data, id }) => {
  return (
    <div className={classes.creditCard}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <img style={{ width: "50px" }} src={data.image} alt="cu" />
      </div>
      <div
        style={{
          margin: "10px 20px 10px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>{data.name}</Typography>
        <Typography style={{ alignSelf: "center", fontSize: "1em" }}>
          {" "}
          {data.amount} x € {data.price}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          whiteSpace: "nowrap",
        }}
      >
        <strong>
          €{" "}
          {Math.round((data.amount * data.price + Number.EPSILON) * 100) / 100}
        </strong>
      </div>
    </div>
  );
};

const SingleReciept = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [cards, setCards] = React.useState(null);
  const { locale } = React.useContext(LocaleContext);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
    } else {
      history.push("/signin");
    }
    async function main() {
      let res = await BuyByeService.getUserShop(props.match.params.id);
      if (res.success) {
        setCards(res.result);
        console.log(res.result);
      }
    }
    main();
  }, [history, props]);
  const onClose = () => {
    history.goBack();
    return;
  };
  if (!cards) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  const onClick = (id) => (e) => {
    history.push(`/case-management?sessUUID=${id.sessionUUID}&_ref=${id._id}`);
    return;
  };

  console.log("hey");

  return (
    <AppLayout
      style={{ overflowY: "scroll" }}
      title={`${Languages[locale].purchaseLabel} ${
        cards ? cards.sessionUUID : ""
      }`}
      bottomNav
      onClick={onClose}
    >
      <Container>
        <div className="header">
          <HeaderItem
            data={{
              title: Languages[locale].receiptSubtitle(
                cards ? cards.totalProduct : 0
              ),
              subtitle: cards
                ? `${moment(cards.endDate).format("DD/MM/YYYY")} ${moment(
                    cards.endDate
                  ).format("hh:mm")}.`
                : "",
            }}
            onClick={onClick(cards)}
          ></HeaderItem>
        </div>

        {cards &&
          cards.products.length > 0 &&
          cards.products.map((card) => (
            <ProductsCard key={Math.random()} classes={classes} data={card} />
          ))}
        <TotalCard classes={classes} data={cards ? cards : {}} />
        <div className={classes.tripTime}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: Languages[locale].receiptDuration(
                cards ? cards.duration : 0
              ),
            }}
          ></Typography>
        </div>
      </Container>
    </AppLayout>
  );
};
export default SingleReciept;
