import React from "react";
import AppLayout from "./layout";
import ReactCodeInput from "react-code-input";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../components/container";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import BuyByeService from "../services/buybye-service";
import ErrorMessage from "../components/errorMessage";
import clsx from "clsx";
import Loader from "./loader";
//const DNS = process.env.DNS || "app.reckon.ai";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
const useStyles = makeStyles((theme) => ({
  codeContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  header: {
    position: "relative",
    height: "60px",
    //overflow: "hidden",
    margin: "20px 0",
    willChange: "transform, opacity",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    color: "white",
    width: "95%",
  },
  btn: {
    position: "absolute",
    width: "100%",
    top: "76%",
    color: "white",
  },
}));
const Address = ({ data }) => (
  <div>
    <address className="header__address">
      <i className="fas fa-map-marker-alt" />
      {data.subtitle}
    </address>
  </div>
);
const HeaderTitle = ({ data }) => (
  <h1 className="header__title">{data.title}</h1>
);

const HeaderTop = ({ data }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
  </div>
);
const HeaderItem = ({ data }) => {
  return (
    <Container className="header__item">
      <HeaderTop data={data} />
      <Address data={data} />
    </Container>
  );
};
const OpenBuyBye = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState(undefined);
  const { locale } = React.useContext(LocaleContext);
  //const [currentUser, setCurrentUser] = useState(undefined);

  const sendOpen = async (value) => {
    if (value) {
      setLoader(true);
      setValue(value);
      let res = await BuyByeService.OpenBuyByeToReplenish(value);
      console.log(res);
      if (res.success) {
        await BuyByeService.setToken("uuid", {
          id: res.result,
        });
        history.push("/in-shop");
      } else {
        setError(
          res.errorMessage && res.errorMessage.message
            ? res.errorMessage.message
            : "Error"
        );
        setLoader(false);
      }
    } else {
      setError("Insert a valid number.");
    }
  };
  const onClick = () => {
    sendOpen(value);
  };

  const codeStyle = {
    inputStyle: {
      fontFamily:
        "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
      margin: "5px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      fontSize: "1.5em",
      height: "50px",
      textAlign: "center",
      color: "black",
      border: "1px solid lightgray",
    },
    inputStyleInvalid: {
      fontFamily:
        "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
      margin: "5px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      textAlign: "center",
      fontSize: "1.5em",
      height: "50px",
      color: "black",
      border: "1px solid lightgray",
    },
    onChange: async (a) => {
      if (a.length === 4) {
        sendOpen(a);
      }
    },
  };

  if (loader) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  return (
    <AppLayout logo={true} bottomNav>
      <div className={clsx(classes.header)}>
        <HeaderItem
          data={{
            title: Languages[locale].openBuyByeTitle,
            subtitle: Languages[locale].openBuyByeSubtitle,
          }}
        ></HeaderItem>
      </div>
      <ErrorMessage
        errorMessage={{ message: error }}
        height={"1.5em"}
      ></ErrorMessage>

      <div className={clsx(classes.codeContainer)}>
        <ReactCodeInput type="number" fields={4} {...codeStyle} />
      </div>
      <Container>
        <img
          src="https://res.cloudinary.com/reckon/image/upload/v1593016575/reckon-platform/logos/File.jpg"
          alt="Open Cabinet"
          style={{ width: "100%", height: "80%" }}
        />
      </Container>
      <Container className={clsx(classes.btn)}>
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.margin, classes.textField)}
          onClick={onClick}
        >
          {Languages[locale].openBuyBye}
        </Button>
      </Container>
    </AppLayout>
  );
};
export default OpenBuyBye;
