import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import Container from "../components/container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    marginTop: "2.5em",
  },
}));
export default function SignIn() {
  const history = useHistory();
  const classes = useStyles();
  window.addEventListener("popstate", (e) => {
    // Nope, go back to your page
    history.go(1);
  });
  return (
    <AppLayout title={"Replenish"} center>
      <Container className={classes.center}>
        <Typography variant="h5" component="p">
          Replenish the products you want.
        </Typography>
        <Typography variant="body1" component="p">
          Keep the products in the right place.
        </Typography>
        <Typography variant="body1" component="p">
          Close the door to finish your Replenish.
        </Typography>
        <img
          src={process.env.PUBLIC_URL + "/1.jpeg"}
          alt="shop"
          style={{ margin: "auto", marginTop: "8vh", width: "100%" }}
        />
      </Container>
    </AppLayout>
  );
}
