import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import Copyright from "../components/copyright";
import { useHistory } from "react-router-dom";
import BackButton from "../components/backButton";
import logo from "../FoodSVG.svg";
import AuthService from "../services/auth-service";
import useStyles from "../css/signup";
import ErrorMessage from "../components/errorMessage";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = React.useState(undefined);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { locale } = React.useContext(LocaleContext);
  const handleOnChange = (value) => {
    setPhone(value);
  };
  const handleChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault({
      username: name,
      phone,
      password,
      email,
      role: "user",
      locale,
    });
    //{username, phone, password, role: user}
    const res = await AuthService.register({
      username: name,
      phone,
      password,
      email: email.toLowerCase(),
      role: "user",
      locale,
    });
    if (res.success) {
      history.push("/verify");
    } else {
      setError(res.errorMessage.message);
    }
  };

  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <BackButton history={history} location={"/signin/"} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} className={classes.avatar} alt="avatar" />
          <ErrorMessage
            errorMessage={{ message: error }}
            height={"1.5em"}
          ></ErrorMessage>
          <form className={classes.form} noValidate onSubmit={handleRegister}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={name}
              onChange={handleChange("name")}
              id="name"
              label={Languages[locale].global.username}
              name="text"
              autoComplete="name"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={email}
              onChange={handleChange("email")}
              id="email"
              label={Languages[locale].global.email}
              name="email"
              autoComplete="email"
            />
            <MuiPhoneNumber
              variant="outlined"
              margin="normal"
              fullWidth
              defaultCountry={"pt"}
              onChange={handleOnChange}
              value={phone}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={handleChange("password")}
              name="password"
              label={Languages[locale].global.password}
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Typography variant="body2" color="textSecondary">
              <Checkbox
                onChange={console.log("cu")}
                inputProps={{ "aria-label": "primary checkbox" }}
                locale
              />
              {Languages[locale].global.acceptTerms} <br></br>{" "}
              <a href="">{Languages[locale].global.terms}</a>
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot/password" variant="body2">
                  {Languages[locale].global.forgotPassword}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signin" variant="body2">
                  {Languages[locale].global.signInTip}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </AppLayout>
  );
}
