import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import visa from "payment-icons/min/single/visa.svg";
import maestro from "payment-icons/min/single/maestro.svg";
import mastercard from "payment-icons/min/single/mastercard.svg";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import BuyByeService from "../services/buybye-service";
import Loader from "../pages/loader";
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles((theme) => ({
  cardNumber: {
    marginBottom: "0px",
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "space-around",
  },
  icon: {
    display: "inline-block",
    marginRight: "1em",
    width: "45px",
    height: "45px",
    marginBlockStart: "1em",
  },
  trashIcon: {
    marginBlockStart: "1em",
    color: theme.palette.red,
    fontSize: "2em",
    cursor: "pointer",
  },
  creditcards: {
    paddingBottom: "11%",
  },
  paymentHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  floatContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20%",
  },
}));

/*const cards = [
  {
    cardType: "visa",
    cardNumber: "**** 4080",
    cardDate: "04/2020",
    source: visa,
  },
  {
    cardType: "maestro",
    cardNumber: "**** 4081",
    cardDate: "05/2020",
    source: maestro,
  },
  {
    cardType: "mastercard",
    cardNumber: "**** 4082",
    cardDate: "06/2020",
    source: mastercard,
  },
];*/

const PaymentCards = ({ classes, data }) => {
  const history = useHistory();
  const onClick = (card) => async (e) => {
    console.log(card);

    let res = await BuyByeService.deleteCustomerCard(card._original);
    if (res.success) {
      history.go(0);
    } else {
      console.log(res);
    }
  };
  const setDefault = (card) => async (e) => {
    console.log(card);

    let res = await BuyByeService.setCustomerDefaultCard(card._original);
    if (res.success) {
      history.go(0);
    } else {
      console.log(res);
    }
  };
  return (
    <div className={classes.creditCard}>
      <Checkbox
        checked={data.default_card ? data.default_card : false}
        style={{
          alignSelf: "center",
          alignContent: "center",
          marginTop: "0.5em",
        }}
        onChange={setDefault(data)}
        color="primary"
      />
      <img className={classes.icon} src={data.source} alt="Logo" />
      <div>
        <h4 className={clsx(classes.cardNumber)}>{data.cardNumber}</h4>
        <span>Expiration Date: {data.cardDate}</span>
      </div>
      <div>
        <DeleteForeverIcon
          className={clsx(classes.trashIcon)}
          onClick={onClick(data)}
        />
      </div>
    </div>
  );
};

const NoCards = () => {
  return <Typography>Register your 1st Card.</Typography>;
};
const cardLogo = (brand) => {
  if (brand.toLowerCase() === "visa") return visa;
  else if (brand.toLowerCase() === "maestro") return maestro;
  else return mastercard;
};
export default function Payments() {
  const history = useHistory();
  const classes = useStyles();
  const [cards, setCards] = React.useState(undefined);

  useEffect(() => {
    async function main() {
      let _cards;
      _cards = await BuyByeService.getCustomerCards();
      console.log(_cards);
      if (_cards.success) {
        _cards = _cards.result.map((card) => {
          return {
            cardType: card.brand.toLowerCase(),
            cardNumber: `**** ${card.number}`,
            cardDate: `${card.exp_month}/${card.exp_year}`,
            source: cardLogo(card.brand),
            default_card: card.default_card,
            _original: card,
          };
        });
        setCards(_cards);
      }
    }
    main();
  }, []);
  const onClick = () => {
    history.push("/card/form");
  };
  if (!cards) {
    return (
      <div style={{ height: "50vh" }}>
        <Loader></Loader>
      </div>
    );
  }
  return (
    <div className={clsx()}>
      <div className={clsx(classes.paymentHeader)}>
        <h1 className={"header__address"}>Payment Cards</h1>
      </div>

      <div className={classes.creditcards}>
        {cards &&
          cards.map((card) => (
            <PaymentCards
              key={`${card.cardNumber}`}
              classes={classes}
              data={card}
            />
          ))}
        {!cards || (cards.length <= 0 && <NoCards></NoCards>)}
      </div>
      <div className={classes.floatContainer}>
        <Fab
          color="primary"
          aria-label="add"
          style={{ color: "white" }}
          onClick={onClick}
        >
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
}
