import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { useHistory } from "react-router-dom";
import KitchenIcon from "@material-ui/icons/Kitchen";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
const BottomNav = ({ match, location }) => {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      top: "auto",
      bottom: 0,
    },
    grow: {
      flexGrow: 1,
    },
    fabButton: {
      position: "absolute",
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: "0 auto",
    },
  }));
  const classes = useStyles();
  let params = { id: history.location.pathname.replaceAll("/", "") };
  console.log(history.location.pathname);
  const userId = params.id ? params.id : "cabinet";
  const [value, setValue] = React.useState(userId);
  const { locale } = React.useContext(LocaleContext);
  console.log(locale);

  const routeChange = (value) => {
    let path = "";
    switch (value) {
      case "home":
        path = `/home`;
        break;
      case "profile":
        path = `/profile`;
        break;
      case "receipts":
        path = `/${value}`;
        break;
      case "cabinet":
        path = `/${value}`;
        break;
      // expected output: "Mangoes and papayas are $2.79 a pound."
      default:
        path = `/app/${value}`;
        break;
    }
    history.push(path);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    routeChange(newValue);
  };
  return (
    <AppBar color="primary" className={classes.appBar}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
        style={{ height: "11vh" }}
      >
        <BottomNavigationAction
          label={Languages[locale].buybye}
          value="cabinet"
          icon={<KitchenIcon />}
        />
        <BottomNavigationAction
          label={Languages[locale].purchaseList}
          value="receipts"
          icon={<ReceiptIcon />}
        />
        <BottomNavigationAction
          label={Languages[locale].account}
          value="profile"
          icon={<AccountCircleIcon />}
        />
      </BottomNavigation>
    </AppBar>
  );
};

export default BottomNav;
