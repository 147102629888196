import AuthService from "./auth-service";
import queryString from "query-string";
import axios from "axios";
const fetch = AuthService.fetch;
const encryptData = AuthService.encryptData;

const timeDifference = (date1, date2) => {
  var difference = date2.getTime() - date1.getTime();

  var minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  return minutesDifference;
};

const getToken = async (token = "authToken") => {
  return await JSON.parse(localStorage.getItem(token));
};

const setToken = async (token, data) => {
  localStorage.setItem(token, JSON.stringify({ ...data, time: new Date() }));
  return true;
};

const deleteToken = async (token) => {
  localStorage.removeItem(token);
};

const validateLocal = async (token, expiration = 60) => {
  const _token = await getToken(token);
  let diff = _token
    ? timeDifference(new Date(_token.time), new Date())
    : undefined;
  console.log(_token);
  console.log(diff);
  if (_token && diff !== undefined && _token.success && diff < expiration) {
    console.log("im valid");
    return _token;
  } else {
    return undefined;
  }
};

const requestWrapper = async (method, url, data) => {
  let authToken = await getToken();
  let diff = authToken
    ? timeDifference(new Date(authToken.time), new Date())
    : 9999;
  if (authToken && diff < 8 && authToken.user) {
    let token = authToken.token;
    console.log(url + "?" + queryString.stringify({ _a: "a", ...data }));
    let result = await fetch(
      method,
      url + "?" + queryString.stringify({ _a: "a", ...data }),
      { _a: "a", ...data },
      {
        authtoken: token,
      }
    );
    return result.data;
  } else {
    deleteToken("authToken");

    let user = AuthService.getCurrentUser();
    let aux = {
      clientID: user ? user._id || user.idUser : null,
      user,
    };
    if (!user) {
      delete aux.clientID;
      delete aux.user;
    }
    let resEncrypt = await encryptData({
      dns: "buybye.reckon.ai",
      data: aux,
    });

    setToken("authToken", {
      token: `${resEncrypt.data.result}`,
      user: user ? true : false,
    });
    console.log(url + "?" + queryString.stringify({ _a: "a", ...data }));
    let result = await fetch(
      method,
      url + "?" + queryString.stringify({ _a: "a", ...data }),
      { _a: "a", ...data },
      {
        authtoken: resEncrypt.data.result,
      }
    );

    return result.data;
  }
};

const openBuyByeToShop = async (machine) => {
  let result = await requestWrapper(
    "PUT",
    "https://buybye.reckon.ai/app/OpenBuyByeToShop/",
    { machineCode: `${machine}`, payment: "stripe" }
  );
  if (result.success) deleteToken("shops");
  return result;
};

const OpenBuyByeToReplenish = async (machine) => {
  let result = await requestWrapper(
    "PUT",
    "https://buybye.reckon.ai/backOffice/OpenBuyByeToReplenish/",
    { machineCode: `${machine}`, payment: "stripe" }
  );
  if (result.success) deleteToken("shops");
  return result;
};

const getUserShops = async () => {
  const p = await validateLocal("shops", 60);
  if (p) {
    return p;
  } else {
    let result = await requestWrapper(
      "get",
      "https://buybye.reckon.ai/app/shops/",
      {}
    );
    setToken("shops", result);
    return result;
  }
};

const getUserShop = async (id) => {
  let result = await requestWrapper(
    "get",
    "https://buybye.reckon.ai/app/shop/",
    { idShop: id }
  );

  return result;
};

const getProducts = async () => {

  let result = await requestWrapper(
    "get",
    "https://buybye.reckon.ai/backOffice/products/",
    { active: true, limit: 100, _extended: true }
  );
  setToken("products", result);
  console.log(result);
  return result;
  // }
};

const getProductsMachine = async (machine) => {
  let result = await requestWrapper(
    "get",
    "https://buybye.reckon.ai/backOffice/app/products/machine",
    { machineCode: `${machine}`, _extended: true, limit:100 }
  );
  console.log(result);
  let c = await requestWrapper(
    "get",
    "https://buybye.reckon.ai/backOffice/machines"
  )
  console.log(c);
  return result;
};

const getCustomerCards = async () => {
  const p = await validateLocal("cards", 60);
  if (p) {
    return p;
  } else {
    let result = await requestWrapper(
      "get",
      "https://buybye.reckon.ai/app/customer/card",
      { payment: "stripe" }
    );

    setToken("cards", result);
    return result;
  }
};
const createCustomerCard = async (card) => {
  deleteToken("cards");
  console.log(card);
  let result = await requestWrapper(
    "post",
    "https://buybye.reckon.ai/app/customer/card",
    { card, payment: "stripe" }
  );

  return result;
};
const setCustomerDefaultCard = async (card) => {
  deleteToken("cards");
  let result = await requestWrapper(
    "put",
    "https://buybye.reckon.ai/app/customer/change/card",
    { card, payment: "stripe" }
  );

  return result;
};

const deleteCustomerCard = async (card) => {
  deleteToken("cards");
  let result = await requestWrapper(
    "delete",
    "https://buybye.reckon.ai/app/customer/card",
    { card, payment: "stripe" }
  );

  console.log("delete customer card", result);
  return result;
};

const customerPayDebt = async (card) => {
  let result = await requestWrapper(
    "delete",
    "https://buybye.reckon.ai/app/customer/pay/debt",
    { card, payment: "stripe" }
  );

  console.log("customer pay debt", result);
  return result;
};

const createCase = async (props) => {
  deleteToken("cases");
  let result = await requestWrapper(
    "POST",
    "https://buybye.reckon.ai/app/caseManagement",
    { ...props }
  );

  console.log("create case", result);
  return result;
};

const updateCase = async (props) => {
  let result = await requestWrapper(
    "put",
    "https://buybye.reckon.ai/app/caseManagement",
    { ...props }
  );

  console.log("update case", result);
  return result;
};

const getCase = async (id) => {
  let result = await requestWrapper(
    "get",
    "https://buybye.reckon.ai/app/caseManagement",
    { caseManagement: id }
  );

  console.log("get case", result);
  return result;
};

const getUserCases = async (props) => {
  const p = await validateLocal("cases", 60);
  if (p) {
    return p;
  } else {
    let result = await requestWrapper(
      "get",
      "https://buybye.reckon.ai/app/caseManagement/requester",
      { ...props }
    );
    setToken("cases", result);
    console.log("get cases", result);
    return result;
  }
};

const getAllDocuments = async () => {
  let result = await requestWrapper(
    "get",
    "https://buybye.reckon.ai/app/documents",
    { active: false, title: "terms" }
  );

  console.log("get all documents", result);
  return result;
};

const updateStock = async(data) =>{
  let result = await requestWrapper(
    "put",
    "https://buybye.reckon.ai/backOffice/product/machine/shelf",
    { ...data }
  );

  console.log("updated stock", result);
  return result;
}

const getMachineProducts = async () => {
  var data = "";
  var config = {
    method: "get",
    url: "https://unilever.reckon.ai/products/getActiveProducts",
    headers: { "Access-Control-Allow-Origin": "*" },
    data: data,
  };

  const result = await axios(config);

  return result.data;
};

export default {
  setToken,
  getToken,
  getUserCases,
  updateCase,
  getCase,
  createCase,
  openBuyByeToShop,
  getCustomerCards,
  createCustomerCard,
  getUserShops,
  getUserShop,
  getProducts,
  customerPayDebt,
  deleteCustomerCard,
  setCustomerDefaultCard,
  getProductsMachine,
  OpenBuyByeToReplenish,
  getMachineProducts,
  updateStock,
};
