import React, { useEffect } from "react";
import "./styles/common.css";

import LocaleContext from "./context/LocaleContext";

export default function App(props) {
  const [locale, setLocale] = React.useState("pt");

  console.log("estou no app");

  /*useEffect(() => {
    let socket;
    async function main() {
      const user = await AuthService.getCurrentUser();

      if (user) {
        user.id = user.id ? user.id : user._id;
        if (socket) socket.disconnect();
        const encryptData = AuthService.encryptData;

        let resEncrypt = await encryptData({
          dns: "websocket.reckon.ai",
          data: { a: "a" },
        });
        socket = socketIOClient(
          ENDPOINT + `?authtoken=${resEncrypt.data.result}`
        );

        socket.on("connect", async function () {
          socket.emit("register", `${user.id}`);
          socket.on("welcome", (data) => {
            console.log(data);
          });
          socket.on("event", (data) => {
            if (data) {
              switch (data.event) {
                case "openShop":
                  //history.push("/in-shop");
                  // code block
                  break;
                case "closeDoor":
                  history.push("/closeDoor");
                  // code block
                  break;
                case "processShop":
                  // code block
                  if (
                    (data.data.result &&
                      data.data.result.payment &&
                      data.data.result.shopID) ||
                    (data.data.result && data.data.result.shopID)
                  ) {
                    history.push("/receipts/" + data.data.result.shopID);
                    //socket.emit("delete", `${data.app}`);
                    //socket.disconnect();
                    // missing todo remove from uuid list
                  }
                  break;
                case "processPayment":
                  // code block
                  if (
                    (data.data.result &&
                      data.data.result.payment &&
                      data.data.result.shopID) ||
                    (data.data.result && data.data.result.shopID)
                  ) {
                    history.push("/receipts/" + data.data.result.shopID);
                    //socket.emit("delete", `${data.app}`);
                    //socket.disconnect();
                    // missing todo remove from uuid list
                  }
                  break;
                default:
                // code block
              }
            }
          });
        });
      }
    }
    main();
    return history.listen((location) => {
      main();
    });
  }, [history]);*/

  const value = React.useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale]
  );
  return (
    <LocaleContext.Provider value={value}>
      {props.children}
    </LocaleContext.Provider>
  );
}
