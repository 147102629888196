import React from "react";
import Button from "../components/button";
import Container from "../components/container";
import BottomNav from "../components/bottomNav";
import { useSpring, animated } from "react-spring";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LOGO from "../components/logo";
import socketIOClient from "socket.io-client";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth-service";

const ENDPOINT = "https://websocket.reckon.ai";

const AppLayout = ({
  children,
  style,
  title,
  logo,
  onClick,
  bottomNav = false,
  ...props
}) => {
  const history = useHistory();

  React.useEffect(() => {
    let socket;
    async function main() {
      const user = await AuthService.getCurrentUser();

      if (user) {
        user.id = user.id ? user.id : user._id;
        if (socket) socket.disconnect();
        const encryptData = AuthService.encryptData;

        let resEncrypt = await encryptData({
          dns: "websocket.reckon.ai",
          data: { a: "a" },
        });
        socket = socketIOClient(
          ENDPOINT + `?authtoken=${resEncrypt.data.result}`
        );

        socket.on("connect", async function () {
          socket.emit("register", `${user.id}`);
          socket.on("welcome", (data) => {
            console.log(data);
          });
          socket.on("event", (data) => {
            console.log(data);
            if (data) {
              switch (data.event) {
                case "openShop":
                  //history.push("/in-shop");
                  // code block
                  break;
                case "closeDoor":
                  history.push("/closeDoor");
                  // code block
                  break;
                case "processShop":
                  // code block
                  if (
                    (data.data.result &&
                      data.data.result.payment &&
                      data.data.result.shopID) ||
                    (data.data.result && data.data.result.shopID)
                  ) {
                    history.push("/receipts/" + data.data.result.shopID);
                    //socket.emit("delete", `${data.app}`);
                    //socket.disconnect();
                    // missing todo remove from uuid list
                  }
                  break;
                case "processReplenish":
                  history.push("/receipts?machine="+data.machineCode);
                  break;
                case "processPayment":
                  // code block
                  if (
                    (data.data.result &&
                      data.data.result.payment &&
                      data.data.result.shopID) ||
                    (data.data.result && data.data.result.shopID)
                  ) {
                    history.push("/receipts/" + data.data.result.shopID);
                    //socket.emit("delete", `${data.app}`);
                    //socket.disconnect();
                    // missing todo remove from uuid list
                  }
                  break;
                default:
                // code block
              }
            }
          });
        });
      }
    }
    main();
    return history.listen((location) => {
      main();
    });
  }, [history]);

  if (logo) {
    title = <LOGO></LOGO>;
  }
  const mainSpring = useSpring({
    from: { x: 0 },
    x: 1,
  });
  const center = props.center
    ? { display: "flex", justifyContent: "center" }
    : {};
  const onClose = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className="app-layout" style={style}>
      {title ? (
        <Container
          as={animated.div}
          className="nav-bar"
          style={{
            opacity: mainSpring.x.interpolate({
              range: [0, 1],
              output: [0, 1],
            }),
            transform: mainSpring.x
              .interpolate({
                range: [0, 1],
                output: [-100, 0],
              })
              .interpolate((x) => `translate3d(0, ${x}%, 0`),
            ...center,
          }}
        >
          <Button className="nav-bar__back" onClick={onClose}>
            {" "}
            {onClick ? (
              <ArrowBackIcon style={{ fontSize: "1em", marginRight: "5px" }} />
            ) : null}
            {title}
          </Button>
        </Container>
      ) : null}
      {children}
      {bottomNav ? <BottomNav match location /> : null}
    </div>
  );
};
export default AppLayout;
